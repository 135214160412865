import React, { useEffect, useState } from 'react';

const Guess = ({ member, doAnim, isFirst }) => {
  const [divCount, setDivCount] = useState(doAnim ? 0 : 6);
  const [delay, setDelay] = useState(isFirst ? 400 : 150);

  useEffect(() => {
    const timer = setInterval(() => {
      setDivCount((prevCount) => {
        if (prevCount < 6) {
          return prevCount + 1;
        } else {
          clearInterval(timer);
          return prevCount;
        }
      });
      setDelay(600);
    }, delay);

    return () => {
      clearInterval(timer);
    };
  }, [delay]);

  return (
    <div className='guess'>
      <div className={`guess-card ${divCount < 1 ? 'hidden' : ''}`}>
        <img className='avatar' src={member.avatar} alt={member.nickname} title={member.nickname} />
      </div>
      <div className={`guess-card ${divCount < 2 ? 'hidden ' : ''} ${member.country.correct ? 'correct' : 'uncorrect'}`}>
        <img src={`https://flagcdn.com/${member.country.value.toLowerCase()}.svg`} alt={member.country.value} title={member.country.value} />
      </div>
      <div className={`guess-card ${divCount < 3 ? 'hidden ' : ''} ${member.game.correct ? 'correct' : 'uncorrect'}`}>
        {member.game.value !== 'Aucun' ?
          <img src={`assets/games/${member.game.value.replaceAll(' ', '')}.png`} alt={member.game.value} title={member.game.value} /> :
          <p>{member.game.value}</p>
        }
      </div>
      <div className={`guess-card ${divCount < 4 ? 'hidden ' : ''} ${member.age.correct ? 'correct' : 'uncorrect'}`}>
        {getPolygon(member.age.hint)}
        <p>{member.age.value}</p>
      </div>
      <div className={`guess-card ${divCount < 5 ? 'hidden ' : ''} ${member.arrival.correct ? 'correct' : 'uncorrect'}`}>
        {getPolygon(member.arrival.hint)}
        <p>{member.arrival.value}</p>
      </div>
      <div className={`guess-card ${divCount < 6 ? 'hidden ' : ''} ${member.function.correct ? 'correct' : 'uncorrect'}`}>
        <p>{member.function.value.replace('Player', 'Joueur')}</p>
      </div>
    </div>
  );
};

function getPolygon(hint) {
  if (hint === 0) return <div></div>
  return (
    <svg className="polygon" viewBox="0 0 100 100">
      {hint === 1 ?
        <polygon points="100,50 70,50 70,0 30,0 30,50 0,50 50,100" /> //down arrow
        :
        <polygon points="100,50 70,50 70,100 30,100 30,50 0,50 50,0" /> //up arrow
      }
    </svg>
  )
}

export default Guess; 