import React, { useEffect, useState } from "react";
import "../styles/components/About.scss";

const About = ({ setOpen }) => {
  const [backgroundOpacity, setBackgroundOpacity] = useState(0.750);
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setOpacity(1);
    }, 10);
  }, []);

  const close = () => {
    setBackgroundOpacity(0);
    setOpacity(0);
    setTimeout(() => {
      setOpen(false);
    }, 300);
  }

  return (
    <div className="popupBackground" style={{ backgroundColor: `rgba(0, 0, 0, ${backgroundOpacity})` }} onClick={() => close()}>
      <div className="container" style={{ opacity: `${opacity}` }} onClick={(e) => e.stopPropagation()}>
        <span onClick={() => close()} className="closeBtn">&times;</span>
        <div className="body">
          <h1>Comment ça marche ?</h1>
          <p>Tu te considère comme un vrai Ultra ? Teste tes connaissances ! Chaque jour, tu devras deviner un nouveau membre qui est ou qui a été par le passé
            chez Joblife. Pour cela, tu devras d'abord faire un premier essai dans le flou, puis tu seras indiqué vers le bon chemin avec des indices.
            Le but est de réussir avec le moins d'essai possible ! Si tu es fier, tu pourras ensuite facilement partager ta partie sur Twitter et sur les
            réseaux. Chaque jour à minuit, le membre Joblife est réinitialisé pour que tu puisses retenter ta chance ! J'ajouterai peut-être d'autres modes de
            jeux par la suite.
          </p>
          <h1>À propos</h1>
          <p>Ce jeu a été conçu par un <span title="Supporter" style={{ fontStyle: 'italic' }}>Ultra</span> et n'est pas officiel ni affilié à Joblife.
            Il a été inspiré par <a href="https://wordle.louan.me/">Wordle</a>, <a href="https://loldle.net/">Loldle</a> et
            <a href="https://kcdle.fr/site/web/kcdle"> KCdle</a>. Il n'utilise aucun cookie et aucune donnée personnelle
            n'est enregistrée. Si vous souhaitez proposer des ajouts, ou signaler un problème, contactez moi sur <a href="https://x.com/tsuyobnha">Twitter</a> !
          </p>
          <h1>Me soutenir</h1>
          <p>Si vous souhaitez faire vivre mes projets en m'aidant à payer les services qui permettent de les faire fonctionner,
            ou si vous aimez tout simplement mon travail, vous pouvez <a href="https://ko-fi.com/tsuyobnha">m'offrir un café</a> ☕.
          </p>
          <h4>Tsuyo.</h4>
        </div>
      </div>
    </div>
  );
};

export default About;