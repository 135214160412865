import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import "./styles/App.scss";
import "./styles/components/Guess.scss";

import Guess from './components/Guess';
import About from './components/about';

function App() {
  const [data, setData] = useState({});
  const [members, setMembers] = useState({});
  const [inputValue, setInputValue] = useState('');
  const [focused, setFocused] = useState(true);
  const [search, setSearch] = useState([]);
  const [guesses, setGuesses] = useState([]);
  const [disableSearch, setDisableSearch] = useState(false);
  const [win, setWin] = useState(false);
  const [doAnim, setDoAnim] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);

  const [copied, setCopied] = useState(false);

  const inputRef = useRef(null);
  const winRef = useRef(null);

  useEffect(() => {
    if (localStorage.getItem('uuid') === null) {
      localStorage.setItem('uuid', uuidv4());
    }

    fetch("https://dle.tsuyo.fr/get/joblife", {
      headers: {
        'uuid': localStorage.getItem('uuid')
      }
    }).then((response) => response.json())
      .then((responseData) => {
        const { members, ...data } = responseData;
        setData(data);
        setMembers(members);

        //clear local storage data
        if (localStorage.getItem('dleNumber')) {
          const number = parseInt(localStorage.getItem('dleNumber'));
          if (number !== data.number) {
            localStorage.removeItem('dleNumber');
            localStorage.removeItem('dleGuesses');
            localStorage.removeItem('dleWin');
          }
        }

        localStorage.setItem('dleNumber', data.number);
        if (localStorage.getItem('dleGuesses')) {
          const stored = JSON.parse(localStorage.getItem('dleGuesses'));
          setGuesses(stored);
          setMembers(members.filter(obj => !stored.some(storedObj => storedObj.id === obj.id)));

          if (localStorage.getItem('dleWin')) {
            setWin(true);
          }
        }
      });


  }, []);

  async function addGuess(member) {
    setDoAnim(true);

    fetch('https://dle.tsuyo.fr/guess/joblife', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'uuid': localStorage.getItem('uuid')
      },
      body: JSON.stringify({ guess: member.id })
    }).then((response) => response.json())
      .then((data) => {
        const guess = data;
        const victory = guess.victory;

        if (victory) {
          setDisableSearch(true);
          localStorage.setItem('dleWin', true);
          setTimeout(() => {
            setWin(true);
            setTimeout(() => {
              winRef.current.scrollIntoView({ behavior: 'smooth' });
            }, 1);
          }, 4200);
        }

        setInputValue('');
        setSearch([]);
        setMembers(members.filter((obj => obj !== member)));

        const newGuesses = [guess, ...guesses];
        setGuesses(newGuesses);

        localStorage.setItem('dleGuesses', JSON.stringify(newGuesses));
      });
  }

  return (
    <div className='app fadeIn'>
      {Object.keys(data).length > 0 ? <>
        {aboutOpen && <About setOpen={setAboutOpen} />}

        <header>
          <h1 className='logo'>JLdle.fr</h1>
        </header>

        <main>
          {(guesses.length === 0 && !localStorage.getItem('lexique')) &&
            <div className='lexique' id='lexique'>
              <span className="closeBtn" title='Cacher cette boîte pour toujours' onClick={() => {
                localStorage.setItem('lexique', true);
                document.getElementById('lexique').style.display = 'none';
              }} >X</span>
              Devine le membre Joblife du jour !

              <div className='colors'>
                <div>
                  <div className='guess-card correct'></div>
                  Correct
                </div>
                <div>
                  <div className='guess-card uncorrect'></div>
                  Incorrect
                </div>
                <div>
                  <div className='guess-card uncorrect'>
                    <svg className="polygon" viewBox="0 0 100 100">
                      <polygon points="100,50 70,50 70,100 30,100 30,50 0,50 50,0" />
                    </svg>
                  </div>
                  Supérieur
                </div>
                <div>
                  <div className='guess-card uncorrect'>
                    <svg className="polygon" viewBox="0 0 100 100">
                      <polygon points="100,50 70,50 70,0 30,0 30,50 0,50 50,100" />
                    </svg>
                  </div>
                  Inférieur
                </div>
              </div>
            </div>
          }

          {!win &&
            <div className='searchbar'>
              <form onSubmit={(e) => {
                e.preventDefault();
                if (search.length > 0) {
                  addGuess(search[0]);
                }

                inputRef.current.focus();
              }}>
                <div className='input'>
                  <input
                    ref={inputRef}
                    type="text"
                    value={inputValue}
                    placeholder={"Entre le nom d'un membre de Joblife..."}
                    onChange={(e) => {
                      setInputValue(e.target.value);
                      if (e.target.value === "") {
                        setSearch([]);
                      } else setSearch(members.filter((member) => member.nickname.toLowerCase().includes(e.target.value.toLowerCase())));
                    }}
                    onBlur={() => setFocused(false)}
                    onFocus={() => setFocused(true)}
                    disabled={disableSearch}
                  />
                </div>
                <div className='button'>
                  <button type='submit' disabled={disableSearch}>
                    <i className='arrow right'></i>
                  </button>
                </div>
              </form>
            </div>
          }

          {search.length > 0 && focused &&
            <div className='searchbox'>
              {Object.values(search)
                .map((member, index) => (
                  <li key={member.nickname}>
                    <div className='member' style={{ backgroundColor: index % 2 === 0 ? '#282a2e' : '#36383d' }} onMouseDown={(event) => {
                      event.preventDefault();
                      addGuess(member);
                      inputRef.current.focus();
                    }}>
                      <img src={member.avatar} alt={member.nickname} />
                      <p>{member.nickname}</p>
                    </div>
                  </li>
                ))
              }
            </div>
          }

          <p className='count'>{data.victories < 1 ? 'Personne n\'a encore trouvé...' :
            data.victories + (data.victories === 1 ? ' personne a trouvé !' : ' personnes ont trouvé !')}</p>

          <div className='results'>
            {guesses.length > 0 &&
              <div>
                <div className={`categories ${guesses.length === 1 ? 'fadeIn' : ''}`}>
                  <p>Membre</p>
                  <p>Nationalitée</p>
                  <p>Jeu</p>
                  <p>Âge</p>
                  <p>A rejoint en</p>
                  <p>Rôle</p>
                </div>

                <div className='guesses'>
                  {Object.values(guesses)
                    .map((member) => (
                      <Guess key={member.nickname} member={member} doAnim={doAnim} isFirst={guesses.length === 1} />
                    ))
                  }
                </div>
              </div>
            }

            {win &&
              getResults(data, guesses, winRef, copied, setCopied)
            }
          </div>
        </main>
        <footer>
          <div className='extension'>
            <p>Mon extension Joblife est sortie !</p>
            <p>Fonce la télécharger ici :</p>

            <div>
              <a href="https://chromewebstore.google.com/detail/joblife/edkkgmecnlfglghdloengahhdhbdnceg">
                <img src="assets/icons/credits/chrome.png" alt="Chrome Web Store" title='Chrome Web Store' />
              </a>
              <a href="https://addons.mozilla.org/fr/firefox/addon/joblife/">
                <img src="assets/icons/credits/firefox.png" alt="Firefox Add-ons" title='Firefox Add-ons' />
              </a>
            </div>
          </div>
          <div className='credit'>
            <img className='icon jl' src="assets/icon.png" alt="Joblife" title='Joblife' />
            <div className='links'>
              <a className='container' href={data.socials.twitter} target='_blank' rel="noreferrer">
                <img onMouseOver={e => {
                  e.currentTarget.src = 'assets/icons/colored/X.png'
                }} onMouseLeave={e => {
                  e.currentTarget.src = 'assets/icons/X.png'
                }} src={'assets/icons/X.png'} alt="X" />
              </a>
              <a className='container' href={data.socials.youtube} target='_blank' rel="noreferrer">
                <img onMouseOver={e => {
                  e.currentTarget.src = 'assets/icons/colored/youtube.png'
                }} onMouseLeave={e => {
                  e.currentTarget.src = 'assets/icons/youtube.png'
                }} src={'assets/icons/youtube.png'} alt="YouTube" />
              </a>
              <a className='container' href={data.socials.instagram} target='_blank' rel="noreferrer">
                <img onMouseOver={e => {
                  e.currentTarget.src = 'assets/icons/colored/instagram.png'
                }} onMouseLeave={e => {
                  e.currentTarget.src = 'assets/icons/instagram.png'
                }} src={'assets/icons/instagram.png'} alt="Instagram" />
              </a>
              <a className='container tiktok' href={data.socials.tiktok} target='_blank' rel="noreferrer">
                <img onMouseOver={e => {
                  e.currentTarget.src = 'assets/icons/colored/tiktok.png'
                }} onMouseLeave={e => {
                  e.currentTarget.src = 'assets/icons/tiktok.png'
                }} src={'assets/icons/tiktok.png'} alt="Tiktok" />
              </a>
            </div>
          </div>
          <div className='credit'>
            <img className='icon' src="assets/icons/credits/tsuyo.png" alt="Tsuyo" title='Tsuyo - Créateur du JLdle' />
            <div className='links'>
              <a className='container' href='https://x.com/tsuyobnha' target='_blank' rel="noreferrer">
                <img onMouseOver={e => {
                  e.currentTarget.src = 'assets/icons/colored/X.png'
                }} onMouseLeave={e => {
                  e.currentTarget.src = 'assets/icons/X.png'
                }} src={'assets/icons/X.png'} alt="X" />
              </a>
              <a className='container' href="https://ko-fi.com/tsuyobnha" target='_blank' rel="noreferrer">
                <img className='ko-fi' src="assets/icons/credits/ko-fi.png" alt="Ko-Fi" title='Me supporter sur Ko-Fi' />
              </a>
              <div className='container'>
                <img className='about' onClick={() => setAboutOpen(true)} src="assets/icons/credits/about.png" alt="À propos" title='À propos' />
              </div>
              <div className='blank'></div>
            </div>
          </div>
          <p className='copyright'>JLdle.fr - 2024</p>
        </footer>
      </> : <></>
      }
    </div >
  )
};

function getResults(data, guesses, ref, copied, setCopied) {
  let results = "";
  let maxLines = 5;
  let extraLines = guesses.length > maxLines ? guesses.length - maxLines : 0;

  guesses.slice(0, maxLines).forEach((member) => {
    results += guess(member) + "\n";
  });

  const ref1 = React.createRef(), ref2 = React.createRef(), ref3 = React.createRef();

  return <div className='win fadeIn' ref={ref}>
    <p className="upper" ref={ref1}>{`J'ai trouvé le membre Joblife caché N°${data.number} sur #JLdle en ${guesses.length} essai${guesses.length > 1 ? 's' : ''} !`}</p>
    <span ref={ref2}>{results}</span>
    {extraLines > 0 &&
      <p className="bottom" ref={ref3}>{`et ${extraLines} de plus...\n`}</p>
    }

    <div className='buttons'>
      <button className='share' onClick={() => {
        window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(getText() + '\n🌐 https://jldle.fr/ #JOBLIFE ')}`, '_blank');
      }}>
        <img src="assets/icons/X.png" alt="" />
        Poster
      </button>

      <button className='copy' onClick={() => {
        const textToCopy = getText() + '\nhttps://jldle.fr/';
        if (navigator.clipboard) {
          navigator.clipboard.writeText(textToCopy);
        } else {
          let textArea = document.createElement('textarea');
          textArea.value = textToCopy;
          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();
          try {
            document.execCommand('copy');
          } catch (err) { }
          document.body.removeChild(textArea);
        }
        if (!copied) {
          setCopied(true);
          setTimeout(() => {
            setCopied(false);
          }, 2000);
        }
      }}>
        <img src="assets/icons/copy.png" alt="" />
        {copied ? 'Copié !' : 'Copier'}
      </button>
    </div>
  </div>;

  function guess(guess) {
    let result = "";
    let categories = ['country', 'game', 'age', 'arrival', 'function'];

    result += guess.victory ? '🟩' : '🟥';
    categories.forEach((cat) => {
      let value = guess[cat];
      result += value.correct ? '🟩' : '🟥';
    });

    return result;
  }

  function getText() {
    let textToCopy = `${ref1.current.textContent}\n\n${ref2.current.textContent}`;
    if (extraLines > 0) {
      textToCopy += `    ${ref3.current.textContent}`;
    }

    return textToCopy;
  }
}

export default App;